const ACTION_SCOPE = '[Assistants]';

export namespace AssistantsActions {
    export class LoadAssistants {
        static readonly type = `${ACTION_SCOPE} List Assistant`
        constructor(public uri: string) { }
    }

    export class SelectedAssistant {
        static readonly type = `${ACTION_SCOPE} Selected Assistant`
        constructor(public assistant: any) { }
    }

    export class CreateAssistantIntent {
        static readonly type = `${ACTION_SCOPE} Create Assistant Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class CreateAssistantIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Create Assistant Intent Success`;
        constructor() { }
    }

    export class CreateAssistantIntentFailed {
        static readonly type = `${ACTION_SCOPE} Create Assistant Intent Failed`;
        constructor() { }
    }

    export class EditAssistantIntent {
        static readonly type = `${ACTION_SCOPE} Edit Assistant Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class EditAssistantIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Edit Assistant Intent Success`;
        constructor() { }
    }

    export class EditAssistantIntentFailed {
        static readonly type = `${ACTION_SCOPE} Edit Assistant Intent Failed`;
        constructor() { }
    }

    export class DeleteAssistantIntent {
        static readonly type = `${ACTION_SCOPE} Delete Assistant Intent`;
        constructor(public uri: string) { }
    }

    export class DeleteAssistantIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Delete Assistant Intent Success`;
        constructor() { }
    }

    export class DeleteAssistantIntentFailed {
        static readonly type = `${ACTION_SCOPE} Delete Assistant Intent Failed`;
        constructor() { }
    }

    export class LoadConfiguration {
        static readonly type = `${ACTION_SCOPE} Get Configuration`
        constructor(public uri: string) { }
    }

    export class LoadConfigurationFiles {
        static readonly type = `${ACTION_SCOPE} List Configuration Files`
        constructor(public uri: string) { }
    }

    export class LoadFunctionsAssistant {
        static readonly type = `${ACTION_SCOPE} List Function Assistant`
        constructor(public uri: string) { }
    }

    export class LoadFunctions {
        static readonly type = `${ACTION_SCOPE} List Function`
        constructor(public uri: string) { }
    }

    export class EditConfigurationIntent {
        static readonly type = `${ACTION_SCOPE} Edit Configuration Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class EditConfigurationIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Edit Configuration Intent Success`;
        constructor() { }
    }

    export class EditConfigurationIntentFailed {
        static readonly type = `${ACTION_SCOPE} Edit Configuration Intent Failed`;
        constructor() { }
    }

    export class AddFunctionIntent {
        static readonly type = `${ACTION_SCOPE} Add Function Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class AddFunctionIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Add Function To Assistant Intent Success`;
        constructor() { }
    }

    export class AddFunctionIntentFailed {
        static readonly type = `${ACTION_SCOPE} Add Function To Assistant Intent Failed`;
        constructor() { }
    }

    export class DeleteFunctionIntent {
        static readonly type = `${ACTION_SCOPE} Delete Function From Assistant Intent`;
        constructor(public uri: string) { }
    }

    export class DeleteFunctionIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Delete Function From Assistant Intent Success`;
        constructor() { }
    }

    export class DeleteFunctionIntentFailed {
        static readonly type = `${ACTION_SCOPE} Delete Function From Assistant Intent Failed`;
        constructor() { }
    }

    export class ResetFunctionList {
        static readonly type = `${ACTION_SCOPE} Reset Function List`
        constructor() { }
    }

    export class ResetConfig {
        static readonly type = `${ACTION_SCOPE} Reset Configuration`
        constructor() { }
    }

    export class LoadAssistantTemplates {
        static readonly type = `${ACTION_SCOPE} List Assistant Templates`
        constructor(public uri: string) { }
    }

    export class LoadFiles {
        static readonly type = `${ACTION_SCOPE} List Files`
        constructor(public uri: string) { }
    }

    export class ResetFileList {
        static readonly type = `${ACTION_SCOPE} Reset File List`
        constructor() { }
    }

    export class LoadFilesets {
        static readonly type = `${ACTION_SCOPE} List Filesets`
        constructor(public uri: string) { }
    }

    export class GetAssistantSync {
        static readonly type = `${ACTION_SCOPE} Get Assistant Sync`
        constructor(public uri: string) { }
    }

    export class PublishAssistantIntent {
        static readonly type = `${ACTION_SCOPE} Publish Assistant Intent`;
        constructor(public uri: string) { }
    }

    export class PublishAssistantIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Publish Assistant Intent Success`;
        constructor() { }
    }

    export class PublishAssistantIntentFailed {
        static readonly type = `${ACTION_SCOPE} Publish Assistant Intent Failed`;
        constructor() { }
    }

    export class LoadFileBuildersAssistant {
        static readonly type = `${ACTION_SCOPE} List FileBuilders Assistant`
        constructor(public uri: string) { }
    }

    export class LoadSuggestionsAssistant {
        static readonly type = `${ACTION_SCOPE} List Suggestions Assistant`
        constructor(public uri: string) { }
    }

    export class CreateSuggestionAssistantIntent {
        static readonly type = `${ACTION_SCOPE} Create Suggestion Assistant Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class CreateSuggestionAssistantIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Create Suggestion Assistant Intent Success`;
        constructor() { }
    }

    export class CreateSuggestionAssistantIntentFailed {
        static readonly type = `${ACTION_SCOPE} Create Suggestion Assistant Intent Failed`;
        constructor() { }
    }

    export class EditSuggestionAssistantIntent {
        static readonly type = `${ACTION_SCOPE} Edit Suggestion Assistant Intent`;
        constructor(public payload: any, public uri: string) { }
    }

    export class EditSuggestionAssistantIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Edit Suggestion Assistant Intent Success`;
        constructor() { }
    }

    export class EditSuggestionAssistantIntentFailed {
        static readonly type = `${ACTION_SCOPE} Edit Suggestion Assistant Intent Failed`;
        constructor() { }
    }

    export class DeleteSuggestionAssistantIntent {
        static readonly type = `${ACTION_SCOPE} Delete Suggestion Assistant Intent`;
        constructor(public uri: string) { }
    }

    export class DeleteSuggestionAssistantIntentSuccess {
        static readonly type = `${ACTION_SCOPE} Delete Suggestion Assistant Intent Success`;
        constructor() { }
    }

    export class DeleteSuggestionAssistantIntentFailed {
        static readonly type = `${ACTION_SCOPE} Delete Suggestion Assistant Intent Failed`;
        constructor() { }
    }

}