
import { Action, Selector, State, StateContext } from "@ngxs/store"
import { User } from "../../models/user"
import { Injectable } from "@angular/core"
import { HttpClient } from "@angular/common/http"
import { AuthActions } from "./auth.actions"
import { catchError, mergeMap, tap, throwError } from "rxjs"
import { SessionToken } from "../../models/session-token"


export class AuthStateModel {
    loading: boolean | undefined
    session: SessionToken | undefined | null
    hash: any
    error: any
    userLogged: User | null | undefined
    greetings: boolean | undefined
    registered: boolean | undefined
    state: string | null | undefined
    maintenance: boolean | undefined
}

const defaults: AuthStateModel = {
    loading: false,
    session: null,
    hash: null,
    error: null,
    userLogged: null,
    greetings: false,
    registered: false,
    state: null,
    maintenance: false
};

@State<AuthStateModel>({
    name: 'auth',
    defaults
})
@Injectable()
export class AuthState {

    @Selector()
    static getSession(state: AuthStateModel) { return state.session; }

    @Selector()
    static getUserLogged(state: AuthStateModel) { return state.userLogged; }

    @Selector()
    static isLoading(state: AuthStateModel) { return state.loading; }

    @Selector()
    static getAuthError(state: AuthStateModel) { return state.error; }

    constructor(private http: HttpClient) { }

    @Action(AuthActions.LoginUser)
    loginUser(ctx: StateContext<AuthStateModel>, { payload, uri }: AuthActions.LoginUser) {
        ctx.patchState({ loading: true, error: null, greetings: false });
        return this.http.post(uri, payload).pipe(tap((result: any) => {
            ctx.patchState({
                greetings: true,
                hash: payload.password,
                session: result
            })
        }),
            mergeMap(() => {
                ctx.patchState({ loading: false });
                return ctx.dispatch(new AuthActions.LoginSuccess())
            }),
            catchError(err => {
                ctx.patchState({ loading: false, error: err.error.message });
                return ctx.dispatch(new AuthActions.LoginFailed())
            })
        )
    }

    @Action(AuthActions.GetUserLogin)
    loadOrganizations(ctx: StateContext<AuthStateModel>, { uri }: AuthActions.GetUserLogin) {
        return this.http.get(uri).pipe(tap((resp: any) => {
            ctx.patchState({
                userLogged: resp.entity.user
            });
        }),
            catchError((err) => {
                ctx.patchState({ loading: false });
                return throwError(() => err);
            }));
    }

    @Action(AuthActions.LogoutUser)
    logoutUser(ctx: StateContext<AuthStateModel>, { uri }: AuthActions.LogoutUser) {
        return this.http.post(uri, null).pipe(tap((result) => {
            ctx.setState({ ...defaults });
        }),
            mergeMap(() => {
                return ctx.dispatch(new AuthActions.LogoutSuccess())
            }),
            catchError(err => {
                ctx.patchState({ ...ctx.getState(), error: err.error.message });
                return ctx.dispatch(new AuthActions.LogoutFailed())
            })
        );
    }

    @Action(AuthActions.ResetDefaultValue)
    resetDefaultValue(ctx: StateContext<AuthStateModel>, { }: AuthActions.ResetDefaultValue) {
        ctx.setState({ ...defaults });
    }
}

