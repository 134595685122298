import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AssistantsActions } from './assistants.actions';
import { catchError, mergeMap, tap, throwError } from 'rxjs';
import { Assistant } from '../../models/assistant';
import { AssistantConfiguration } from '../../models/assistant-configuration';
import { AssistantTemplate } from '../../models/assistant-template';
import { Files } from '../../models/files';
import { AssistantSync } from '../../models/assistant-sync';
import { Filesets } from '../../models/filesets';
import { FileBuilder } from '../../models/filebuilder';
import { Suggestion } from '../../models/suggestion';

export class AssistantsStateModel {
  loading: boolean | undefined;
  error: any;
  assistantList: Assistant[] | undefined;
  assistantSelected: Assistant | undefined | null;
  next: any;
  previous: any;
  configuration: AssistantConfiguration | undefined | null;
  configurationFileList: Files[] | undefined;
  nextConfigurationFiles: any;
  previousConfigurationFiles: any;
  fileList: Files[] | undefined;
  nextFiles: any;
  filesetList: Filesets[] | undefined;
  nextFilesets: any;
  functionList: Function[] | undefined;
  nextFunction: any;
  functionAssistantList: Function[] | undefined;
  nextFunctionAssistant: any;
  previousFunctionAssistant: any;
  assistantTemplateList: AssistantTemplate[] | undefined;
  nextAssistantTemplate: any;
  assistantSync: AssistantSync | undefined | null;
  fileBuilderAssistantList: FileBuilder[] | undefined;
  nextFileBuilderAssistant: any;
  previousFileBuilderAssistant: any;
  suggestionAssistantList: Suggestion[] | undefined;
  nextSuggestionAssistant: any;
  previousSuggestionAssistant: any;
}

const defaults: AssistantsStateModel = {
  loading: false,
  error: null,
  assistantList: [],
  assistantSelected: null,
  next: null,
  previous: null,
  configuration: null,
  configurationFileList: [],
  nextConfigurationFiles: null,
  previousConfigurationFiles: null,
  functionList: [],
  nextFunction: null,
  fileList: [],
  nextFiles: null,
  filesetList: [],
  nextFilesets: null,
  functionAssistantList: [],
  nextFunctionAssistant: null,
  previousFunctionAssistant: null,
  assistantTemplateList: [],
  nextAssistantTemplate: null,
  assistantSync: null,
  fileBuilderAssistantList: [],
  nextFileBuilderAssistant: null,
  previousFileBuilderAssistant: null,
  suggestionAssistantList: [],
  nextSuggestionAssistant: null,
  previousSuggestionAssistant: null
};

@State<AssistantsStateModel>({
  name: 'assistants',
  defaults,
})
@Injectable()
export class AssistantsState {
  @Selector()
  static isLoading(state: AssistantsStateModel) {
    return state.loading;
  }

  @Selector()
  static getAssistantsError(state: AssistantsStateModel) {
    return state.error;
  }

  @Selector()
  static getAssistantList(state: AssistantsStateModel) {
    return state.assistantList;
  }

  @Selector()
  static getLoadNext(state: AssistantsStateModel) {
    return state.next;
  }

  @Selector()
  static getLoadPrevious(state: AssistantsStateModel) {
    return state.previous;
  }

  @Selector()
  static getAssistantSelected(state: AssistantsStateModel) {
    return state.assistantSelected;
  }

  @Selector()
  static getConfiguration(state: AssistantsStateModel) {
    return state.configuration;
  }

  @Selector()
  static getConfigurationFileList(state: AssistantsStateModel) {
    return state.configurationFileList;
  }

  @Selector()
  static getLoadNextConfigurationFiles(state: AssistantsStateModel) {
    return state.nextConfigurationFiles;
  }

  @Selector()
  static getLoadPreviousConfigurationFiles(state: AssistantsStateModel) {
    return state.previousConfigurationFiles;
  }

  @Selector()
  static getFileList(state: AssistantsStateModel) {
    return state.fileList;
  }

  @Selector()
  static getLoadNextFiles(state: AssistantsStateModel) {
    return state.nextFiles;
  }

  @Selector()
  static getFilesetList(state: AssistantsStateModel) {
    return state.filesetList;
  }

  @Selector()
  static getLoadNextFilesets(state: AssistantsStateModel) {
    return state.nextFilesets;
  }

  @Selector()
  static getFunctionList(state: AssistantsStateModel) {
    return state.functionList;
  }

  @Selector()
  static getLoadNextFunction(state: AssistantsStateModel) {
    return state.nextFunction;
  }

  @Selector()
  static getFunctionAssistantList(state: AssistantsStateModel) {
    return state.functionAssistantList;
  }

  @Selector()
  static getLoadNextFunctionAssistant(state: AssistantsStateModel) {
    return state.nextFunctionAssistant;
  }

  @Selector()
  static getLoadPreviousFunctionAssistant(state: AssistantsStateModel) {
    return state.previousFunctionAssistant;
  }

  @Selector()
  static getAssistantTemplateList(state: AssistantsStateModel) {
    return state.assistantTemplateList;
  }

  @Selector()
  static getLoadNextAssistantTemplate(state: AssistantsStateModel) {
    return state.nextAssistantTemplate;
  }

  @Selector()
  static getAssistantSync(state: AssistantsStateModel) {
    return state.assistantSync;
  }

  @Selector()
  static getFileBuilderAssistantList(state: AssistantsStateModel) {
    return state.fileBuilderAssistantList;
  }

  @Selector()
  static getLoadNextFileBuilderAssistant(state: AssistantsStateModel) {
    return state.nextFileBuilderAssistant;
  }

  @Selector()
  static getLoadPreviousFileBuilderAssistant(state: AssistantsStateModel) {
    return state.previousFileBuilderAssistant;
  }

  @Selector()
  static getSuggestionAssistantList(state: AssistantsStateModel) {
    return state.suggestionAssistantList;
  }

  @Selector()
  static getLoadNextSuggestionAssistant(state: AssistantsStateModel) {
    return state.nextSuggestionAssistant;
  }

  @Selector()
  static getLoadPreviousSuggestionAssistant(state: AssistantsStateModel) {
    return state.previousSuggestionAssistant;
  }

  constructor(private http: HttpClient) { }

  @Action(AssistantsActions.LoadAssistants)
  loadAssistants(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.LoadAssistants
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          assistantList: resp.entities,
          next:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null,
          previous:
            resp && resp.resources.previous
              ? resp.resources.previous.uri.split('?')[1]
              : null,
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false, assistantList: [], next: null, previous: null });
        return throwError(() => err);
      })
    );
  }

  @Action(AssistantsActions.SelectedAssistant)
  selectAssistant(
    ctx: StateContext<AssistantsStateModel>,
    { assistant }: AssistantsActions.SelectedAssistant
  ) {
    ctx.patchState({ assistantSelected: assistant });
  }

  @Action(AssistantsActions.CreateAssistantIntent)
  createAssistantIntent(
    ctx: StateContext<AssistantsStateModel>,
    { payload, uri }: AssistantsActions.CreateAssistantIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.post(uri, payload).pipe(
      tap((result: any) => {
        ctx.patchState({ loading: false, assistantSelected: result });
      }),
      mergeMap(() => {
        return ctx.dispatch(
          new AssistantsActions.CreateAssistantIntentSuccess()
        );
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(
          new AssistantsActions.CreateAssistantIntentFailed()
        );
      })
    );
  }

  @Action(AssistantsActions.EditAssistantIntent)
  editAssistantIntent(
    ctx: StateContext<AssistantsStateModel>,
    { payload, uri }: AssistantsActions.EditAssistantIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.put(uri, payload).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
      }),
      mergeMap(() => {
        return ctx.dispatch(new AssistantsActions.EditAssistantIntentSuccess());
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(new AssistantsActions.EditAssistantIntentFailed());
      })
    );
  }

  @Action(AssistantsActions.DeleteAssistantIntent)
  deleteAssistantIntent(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.DeleteAssistantIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.delete(uri).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
        return ctx.dispatch(
          new AssistantsActions.DeleteAssistantIntentSuccess()
        );
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(
          new AssistantsActions.DeleteAssistantIntentFailed()
        );
      })
    );
  }

  @Action(AssistantsActions.LoadConfiguration)
  loadConfiguration(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.LoadConfiguration
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          configuration: resp,
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(AssistantsActions.LoadConfigurationFiles)
  loadConfigurationFiles(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.LoadConfigurationFiles
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          configurationFileList: resp.entities,
          nextConfigurationFiles:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null,
          previousConfigurationFiles:
            resp && resp.resources.previus
              ? resp.resources.previus.uri.split('?')[1]
              : null
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(AssistantsActions.EditConfigurationIntent)
  editConfigurationIntent(
    ctx: StateContext<AssistantsStateModel>,
    { payload, uri }: AssistantsActions.EditConfigurationIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.put(uri, payload).pipe(
      tap((resp: any) => {
        ctx.patchState({ loading: false, configuration: resp });
      }),
      mergeMap(() => {
        return ctx.dispatch(
          new AssistantsActions.EditConfigurationIntentSuccess()
        );
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(
          new AssistantsActions.EditConfigurationIntentFailed()
        );
      })
    );
  }

  @Action(AssistantsActions.LoadFunctionsAssistant)
  loadFunctionsAssistant(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.LoadFunctionsAssistant
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          functionAssistantList: resp.entities,
          nextFunctionAssistant:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null,
          previousFunctionAssistant:
            resp && resp.resources.previous
              ? resp.resources.previous.uri.split('?')[1]
              : null,
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(AssistantsActions.LoadFunctions)
  loadFunctions(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.LoadFunctions
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          functionList: resp.entities,
          nextFunction:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null,
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(AssistantsActions.AddFunctionIntent)
  addFunctionIntent(
    ctx: StateContext<AssistantsStateModel>,
    { payload, uri }: AssistantsActions.AddFunctionIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.patch(uri, payload).pipe(
      tap((result: any) => {
        ctx.patchState({ loading: false });
      }),
      mergeMap(() => {
        return ctx.dispatch(new AssistantsActions.AddFunctionIntentSuccess());
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(new AssistantsActions.AddFunctionIntentFailed());
      })
    );
  }

  @Action(AssistantsActions.DeleteFunctionIntent)
  deleteFunctionIntent(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.DeleteFunctionIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.delete(uri).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
        return ctx.dispatch(
          new AssistantsActions.DeleteFunctionIntentSuccess()
        );
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(
          new AssistantsActions.DeleteAssistantIntentFailed()
        );
      })
    );
  }

  @Action(AssistantsActions.ResetFunctionList)
  resetFunctionList(
    ctx: StateContext<AssistantsStateModel>,
    { }: AssistantsActions.ResetFunctionList
  ) {
    ctx.patchState({
      functionList: [],
      error: null,
      nextFunction: null,
    });
  }

  @Action(AssistantsActions.ResetFileList)
  resetFileList(
    ctx: StateContext<AssistantsStateModel>,
    { }: AssistantsActions.ResetFunctionList
  ) {
    ctx.patchState({
      fileList: [],
      error: null,
      nextFiles: null,
    });
  }

  @Action(AssistantsActions.ResetConfig)
  resetConfig(
    ctx: StateContext<AssistantsStateModel>,
    { }: AssistantsActions.ResetConfig
  ) {
    ctx.patchState({
      error: null,
      fileList: [],
      configuration: null,
      nextFiles: null,
      filesetList: [],
      nextFilesets: null,
      fileBuilderAssistantList: [],
      nextFileBuilderAssistant: null,
      previousFileBuilderAssistant: null
    });
  }

  @Action(AssistantsActions.LoadAssistantTemplates)
  loadAssistantTemplates(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.LoadAssistantTemplates
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          assistantTemplateList: resp.entities,
          nextAssistantTemplate:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null,
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(AssistantsActions.LoadFiles)
  loadFiles(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.LoadFiles
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          fileList: resp.entities,
          nextFiles:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(AssistantsActions.LoadFilesets)
  loadFilesets(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.LoadFilesets
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          filesetList: resp.entities,
          nextFilesets:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null,
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(AssistantsActions.GetAssistantSync)
  getAssistantSync(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.GetAssistantSync
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          assistantSync: resp,
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(AssistantsActions.PublishAssistantIntent)
  publishAssistantIntent(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.PublishAssistantIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.post(uri, null).pipe(
      tap((result: any) => {
        ctx.patchState({ loading: false });
      }),
      mergeMap(() => {
        return ctx.dispatch(
          new AssistantsActions.PublishAssistantIntentSuccess()
        );
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(
          new AssistantsActions.PublishAssistantIntentFailed()
        );
      })
    );
  }

  @Action(AssistantsActions.LoadFileBuildersAssistant)
  loadFileBuildersAssistant(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.LoadFileBuildersAssistant
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          fileBuilderAssistantList: resp.entities,
          nextFileBuilderAssistant:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null,
          previousFileBuilderAssistant:
            resp && resp.resources.previous
              ? resp.resources.previous.uri.split('?')[1]
              : null,
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(AssistantsActions.LoadSuggestionsAssistant)
  loadSuggestionsAssistant(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.LoadSuggestionsAssistant
  ) {
    ctx.patchState({ loading: true });
    return this.http.get(uri).pipe(
      tap((resp: any) => {
        ctx.patchState({
          loading: false,
          suggestionAssistantList: resp.entities,
          nextSuggestionAssistant:
            resp && resp.resources.next
              ? resp.resources.next.uri.split('?')[1]
              : null,
          previousSuggestionAssistant:
            resp && resp.resources.previous
              ? resp.resources.previous.uri.split('?')[1]
              : null,
        });
      }),
      catchError((err) => {
        ctx.patchState({ loading: false });
        return throwError(() => err);
      })
    );
  }

  @Action(AssistantsActions.CreateSuggestionAssistantIntent)
  createSuggestionAssistantIntent(
    ctx: StateContext<AssistantsStateModel>,
    { payload, uri }: AssistantsActions.CreateSuggestionAssistantIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.post(uri, payload).pipe(
      tap((result: any) => {
        ctx.patchState({ loading: false});
      }),
      mergeMap(() => {
        return ctx.dispatch(
          new AssistantsActions.CreateSuggestionAssistantIntentSuccess()
        );
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(
          new AssistantsActions.CreateSuggestionAssistantIntentFailed()
        );
      })
    );
  }

  @Action(AssistantsActions.EditSuggestionAssistantIntent)
  editSuggestionAssistantIntent(
    ctx: StateContext<AssistantsStateModel>,
    { payload, uri }: AssistantsActions.EditSuggestionAssistantIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.put(uri, payload).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
      }),
      mergeMap(() => {
        return ctx.dispatch(new AssistantsActions.EditSuggestionAssistantIntentSuccess());
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(new AssistantsActions.EditSuggestionAssistantIntentFailed());
      })
    );
  }

  @Action(AssistantsActions.DeleteSuggestionAssistantIntent)
  deleteSuggestionAssistantIntent(
    ctx: StateContext<AssistantsStateModel>,
    { uri }: AssistantsActions.DeleteSuggestionAssistantIntent
  ) {
    ctx.patchState({ loading: true, error: null });
    return this.http.delete(uri).pipe(
      tap(() => {
        ctx.patchState({ loading: false });
        return ctx.dispatch(
          new AssistantsActions.DeleteSuggestionAssistantIntentSuccess()
        );
      }),
      catchError((err) => {
        ctx.patchState({
          loading: false,
          error: err.error.errors
            ? err.error.errors.toString()
            : err.error.message,
        });
        return ctx.dispatch(
          new AssistantsActions.DeleteSuggestionAssistantIntentFailed()
        );
      })
    );
  }
}
