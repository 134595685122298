import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import {
    ButtonDirective,
    CardBodyComponent,
    CardComponent,
    CardGroupComponent,
    ColComponent,
    ContainerComponent, FormControlDirective, FormDirective, InputGroupComponent, InputGroupTextDirective,
    RowComponent
} from '@coreui/angular';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { fade1000 } from '../../../shared/animations/animations';
import { OrganizationsState } from '../../../store/organizations/organizations.state';
import { OrganizationsActions } from '../../../store/organizations/organizations.actions';
import { AuthActions } from '../../../store/auth/auth.actions';
import { ApiPaths } from '../../../shared/enums/api-path.enum';
import { Organization } from '../../../models/organization';
import {IconDirective} from "@coreui/icons-angular";
import { AppActions } from '../../../store/app/app.actions';
import {ApiUtils} from "../../../shared/utils/api.utils";

@Component({
  selector: 'app-login-organization',
  standalone: true,
    imports: [ContainerComponent, RowComponent, ColComponent, CardGroupComponent, CardComponent, CardBodyComponent, FormsModule, ButtonDirective, FormControlDirective, FormDirective, IconDirective, InputGroupComponent, InputGroupTextDirective, ReactiveFormsModule],
  templateUrl: './login-organization.component.html',
  styleUrl: './login-organization.component.scss',
  animations: [fade1000]
})
export class LoginOrganizationComponent implements OnInit, OnDestroy {

  errorObs?: Observable<any> = inject(Store).select(OrganizationsState.getOrganizationError);
  organizationsObs?: Observable<any> = inject(Store).select(OrganizationsState.getOrganizationList);

  private destroy: Subject<boolean> = new Subject<boolean>();

  organizations: Organization[] = [];
  selectedOrganization: Organization | undefined | null;

  constructor(private router: Router, private store: Store) {}

  ngOnInit(): void {
    this.organizationsObs?.subscribe((organizations: []) => {
      if (organizations && organizations.length > 0) {
        this.organizations = organizations;
      }
    });
  }
  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.unsubscribe();
  }

  onSelect(): void {
    this.store.dispatch(new OrganizationsActions.SelectOrganization(this.selectedOrganization));
    this.store.dispatch(new AuthActions.GetUserLogin(ApiUtils.resolveUri(ApiPaths.GetUserLogin)));
    this.store.dispatch(new AppActions.LoadSystemData(ApiUtils.resolveUri(ApiPaths.System)));
    this.router.navigate(['/dashboard']);
  }
}
